import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Container from '@mui/material/Container';

import HeroBanner from '../components/HeroBanner'
import ArticleCard from '../components/press-page/ArticleCard';

import { withHelmet } from '../helpers/metadataHelpers'

export const PressPageTemplate = ({
  title,
  articles,
}) => {
  const sorted = articles.sort((a, b) => a.date < b.date ? 1 : -1)

  return (
    <div>
      <HeroBanner title={title} />
      <Container py={2}>
      { sorted && sorted.map((article) => (
        <ArticleCard {...article}/>
      ))}
      </Container>
    </div>
  )
}

PressPageTemplate.propTypes = {
  title: PropTypes.string,
  articles: PropTypes.array,
}

const PressPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PressPageTemplate
        title={frontmatter.title}
        articles={frontmatter.articles}
      />
    </Layout>
  )
}

PressPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default withHelmet(PressPage)

export const PressPageQuery = graphql`
  {
    markdownRemark(frontmatter: {templateKey: {eq: "press-page"}}) {
      frontmatter {
        pagename
        title
        articles {
          date
          headline
          publication
          url
          quote
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 88) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
