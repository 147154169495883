import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import Moment from 'react-moment';

import { getImagePath } from '../../helpers/imageHelpers';
import { Divider, Hidden } from '@mui/material';

export default function ArticleCard({ image, headline, publication, date, quote, url }) {
  const contentPadding = { xs: 0, sm: 4, md: 5 }
  const imagePadding = {
    paddingY: { xs: 0, sm: 4, md: 6, lg: 8 },
    paddingX: { xs: 0, sm: 4, md: 6 },
  }

  return (
    <Card
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        display: 'flex',
        my: 2,
        p: { xs: 2, sm: 0 }
      }}
      variant="outlined"
    >
      <Hidden smDown>
        <Box sx={{ marginBottom: { xs: 1, sm: 0}, ...imagePadding}}>
          <CardMedia
            sx={{
              width: { xs: 50, sm: 100, md: 150, lg: 200},
              height: { xs: '50px', sm: '100%' },
              backgroundSize: 'contain'
              }}
            image={getImagePath(image)}
            title={`logo for ${publication}`}
          />
        </Box>
      </Hidden>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ padding: contentPadding }}>
        <CardContent sx={{ padding: 0, marginBottom: { xs: 1, sm: 0}, flex: '1 0 auto' }}>
          <Typography variant="overline">
            {publication} | <Moment format="MMM D, YYYY">{date}</Moment>
          </Typography>
          <Typography gutterBottom component="div" variant="h4">
            {headline}
          </Typography>
          {/* <Typography gutterBottom variant="subtitle1" color="text.secondary" component="div">
            <Moment fromNow>{date}</Moment>
          </Typography> */}
          <Typography gutterBottom>{quote}</Typography>
        </CardContent>
        <CardActions sx={{ padding: { xs: 0 }}}>
          <Button sx={{ padding: 0}} size="small" color="primary" target="_blank" href={url}>
            Read more
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
}
